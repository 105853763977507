import React, { useEffect, useState } from "react"
import axios from "axios"

import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"

import TextField from "@material-ui/core/TextField"

import SEO from "../components/seo"

import Box from "@material-ui/core/Box"

import { Button } from "gatsby-theme-material-ui"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

import CircularProgress from "@material-ui/core/CircularProgress"

import InputAdornment from "@material-ui/core/InputAdornment"

import Header from "../components/Header"

import { navigate } from "gatsby"

/**
 * @component Form
 * @props - { object } -  config
 */
const Entrar = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      "& > *": {
        // margin: theme.spacing(1),
      },
    },
    TextField: {
      width: "100%",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },

    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  }))

  const classes = useStyles()

  const [formData, setFormData] = useState({})

  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  })

  const [openA, setOpenA] = React.useState(false)

  const handleClickOpenA = () => {
    setOpenA(true)
  }

  const handleCloseA = () => {
    setOpenA(false)
  }

  /**
   * @function handleFormSubmit
   * @param e { obj } - form event
   * @return void
   */
  const handleFormSubmit = e => {
    e.preventDefault()

    if (!loading) {
      console.log(loading)
      setLoading(true)
    }
    let url = e.target[0].value.toLowerCase()
    if (url.includes("integrada") || url.includes("educativa")) {
      navigate("https://integradaeducativa.grafiame.com.br")
    } else {
      handleClickOpenA()
      setLoading(false)
    }
  }
  /**
   * @function handleChange
   * @param e { obj } - change event
   * @param field { string } - namve of the field
   * @return void
   */
  const handleChange = (e, field) => {
    let value = e.target[0].value
    setFormData({
      ...formData,
      [field]: value,
    })
  }

/* Redirecting to the app. */
  // useEffect(() => {
  //   window.open("https://app.grafiame.vercel.app", "_self")
  // }, [])

  return (
    <>
      <SEO title="Entrar | Grafiame" />
      <main className="me-entrar me-capa">
        <Header />
          <div style={{
            display: 'flex',
            width: '100%',
            height: 'calc(100vh - 83.02px)',
            alignItems: 'center',
            paddingLeft: '5%'
          }}>
            <form
              onSubmit={e => handleFormSubmit(e)}
              action="#"
              className={classes.root}
            >
              <h3 className="me-h3-center">
                Entrar na plataforma<span className="me-the-end">.</span>
              </h3>
              <h5 style={{textAlign: 'center'}}>
                Infantil e Fundamental I<span className="me-the-end">.</span>
              </h5>
              <TextField
                label="Organização"
                name="organization"
                variant="outlined"
                className={classes.TextField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      .grafiame.com.br
                    </InputAdornment>
                  ),
                }}
                required
              />
              <Box
                marginTop="20px !important"
                marginBottom="40px !important"
                display="flex"
                justifyContent="center"
              >
                <div className={classes.wrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={(buttonClassname, "me-btn")}
                    disabled={loading}
                    value="sub"
                  >
                    Entrar
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </Box>
              <hr />
              <Box
                marginTop="50px !important"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <h5 style={{textAlign: 'center'}}>
                    Fundamental II, Ensino médio e particulares<span className="me-the-end">.</span>
                </h5>
                <div className={classes.wrapper}>
                  <Button
                    onClick={() => window.open("https://app.grafiame.com.br", "_self")}
                    variant="contained"
                    color="primary"
                    className={(buttonClassname, "me-btn")}
                  >
                    Entrar
                  </Button>
                </div>
              </Box>
            </form>
          </div>
          <Dialog
            open={openA}
            onClose={handleCloseA}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Ops... Organização não encontrada :("}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Procuramos e procuramos, mas, pelo visto, não achamos sua
                organização. Respira! Isso não é motivo para tristeza! Tente
                pesquisar de outra maneira ou então peça o link direto para
                algum membro de sua organização.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseA} color="primary" autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
      </main>
    </>
  )
}

export default Entrar
//propTypes for the component
